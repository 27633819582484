import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import findIndex from 'lodash/findIndex';
import mergeWith from 'lodash/mergeWith';
import connectToRedux from './connectToRedux';
import composeInProvider from './composeInProvider';

const { isArray, isFunction, isPlainObject, mapValues, difference, filter } = app.helpers;

const registeredReducers = {};
const registeredActions = {};
const actionsDispatchers = {};
let store = null;

app.onStart(configureStore);

// export to global
app.redux = {
  registerReducers,
  connectToRedux,
  composeInProvider,
  getStore: () => store,
  // actions: registeredActions,
  dispatchers: actionsDispatchers,
};

// ///

function configureStore() {
  if (store) {
    app.error('Redux store is ALREADY configured!');

    return;
  }
  store = createStore(
    combineReducers(registeredReducers),
    getInitialState(),
    compose(
      applyMiddleware(thunkMiddleware),
      ...getEnhancers(),
    ),
  );

  return store; // eslint-disable-line consistent-return
}

function getInitialState() {
  const reduxStoreNode = document.querySelector('#redux_store');

  return reduxStoreNode && ({
    rehydratedStore: true,
    ...JSON.parse(reduxStoreNode.getAttribute('data-redux-store')),
  }) || {};
}

function getEnhancers() {
  const enhancers = [];

  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__()); // eslint-disable-line no-underscore-dangle
  }

  return enhancers;
}

function registerReducers(reducers) {
  if (store) {
    app.error('Redux store is ALREADY configured!');

    return;
  }
  Object.assign(
    registeredReducers,
    mapValues(reducers, (initialState, reducerName) => {
      registeredActions[reducerName] = actionsFactory(reducerName);
      actionsDispatchers[reducerName] = actionsDispatchersFactory(reducerName);

      return reducerFactory(reducerName, initialState);
    }),
  );
}

// Reducers DSL-like notation `{ reducerName: initialState | { initialState, defaultReducer } }`,
// used for generation exported reducers below
function reducerFactory(reducerName, initialState) {
  if (isFunction(initialState)) {
    return initialState;
  }
  const { defaultReducer, initialState: iState } = getDefaultReducer(initialState);

  if (isPlainObject(iState) || isArray(iState)) {
    return defaultReducerFactory(reducerName, iState, defaultReducer);
  }

  return basicReducerFactory(reducerName, iState, defaultReducer);
}

function getDefaultReducer(initialState) {
  let defaultReducer = null;

  if (isPlainObject(initialState)) {
    if (isComplexInitialState(initialState)) {
      defaultReducer = initialState.defaultReducer; // eslint-disable-line prefer-destructuring
      initialState = initialState.initialState; // eslint-disable-line prefer-destructuring
      defaultReducer = prepareDefaultReducer(defaultReducer);
    }
  }

  return { defaultReducer, initialState };
}

function prepareDefaultReducer(defaultReducer) {
  if (isPlainObject(defaultReducer)) {
    return makeDefaultReducer(defaultReducer);
  }

  return defaultReducer;
}

function isComplexInitialState(initialState) {
  const keys = Object.keys(initialState);

  return keys.length > 0 && difference(keys, ['initialState', 'defaultReducer']).length === 0;
}

function makeDefaultReducer(reducerRules) {
  return (state, action, initialState) => {
    if (action.type in reducerRules) {
      return reducerRules[action.type](state, action, initialState);
    }

    return state;
  };
}

function basicReducerFactory(reducerName, initialState, defaultReducer = null) {
  return (state = initialState, action) => {
    switch (action.type) {
      case `SET_${reducerName}`:
        return isPlainObject(initialState) ? ({ ...initialState, ...action.data }) : action.data;
      case `CLEAR_${reducerName}`:
        return initialState;
      default:
        return defaultReducer ? defaultReducer(state, action, initialState) : state;
    }
  };
}

function defaultReducerFactory(reducerName, initialState, defaultReducer = null) {
  return basicReducerFactory(reducerName, initialState, (state = initialState, action) => {
    switch (action.type) {
      case `APPEND_${reducerName}`:
        return state.concat(action.data);
      case `PATCH_${reducerName}`:
        return patchReducer(state, action.patch);
      case `REMOVE_${reducerName}`:
        return filter(state, item => item.id !== action.id);
      default:
        return defaultReducer ? defaultReducer(state, action, initialState) : state;
    }
  });
}

function patchReducer(state, patch) {
  if (isPlainObject(state)) {
    if (patch.id && state.id && patch.id !== state.id) {
      return state;
    }

    return mergeWith({}, state, patch, (stateValue, patchValue) => (isArray(patchValue) ? patchValue : undefined));
  }

  return patchState(state);
}

function patchState(state, patch) {
  const itemIndex = findIndex(state, { id: patch.id });
  const newState = [...state];

  if (itemIndex >= 0) {
    newState[itemIndex] = {

      ...newState[itemIndex],
      ...patch,
    };
  } else {
    newState.unshift(patch);
  }
}

function actionsFactory(reducerName) {
  return {
    setStore: data => ({ type: `SET_${reducerName}`, data }),
    clearStore: () => ({ type: `CLEAR_${reducerName}` }),
    appendStore: data => ({ type: `APPEND_${reducerName}`, data }),
    patch: patch => ({ type: `PATCH_${reducerName}`, patch }),
    remove: id => ({ type: `REMOVE_${reducerName}`, id }),
  };
}

function actionsDispatchersFactory(reducerName) {
  return {
    setStore: data => dispatchAction(reducerName, 'setStore', data),
    clearStore: () => dispatchAction(reducerName, 'clearStore'),
    appendStore: data => dispatchAction(reducerName, 'appendStore', data),
    patch: patch => dispatchAction(reducerName, 'patch', patch),
    remove: id => dispatchAction(reducerName, 'remove', id),
  };
}

function dispatchAction(reducerName, actionName, ...arguments_) {
  if (!store) {
    app.error('Redux store is NOT yet configured!');

    return;
  }

  // eslint-disable-next-line consistent-return
  return store.dispatch(registeredActions[reducerName][actionName](...arguments_));
}
