import { ICON, iconMarkup, errorIconMarkup, 
// warningIconMarkup,
successIconMarkup, } from '../markup/icons';
var ICON_CONTENTS = {
    error: errorIconMarkup(),
    // warning: warningIconMarkup(),
    success: successIconMarkup(),
};
/*
 * Set the warning, error, success or info icons:
 */
var initPredefinedIcon = function (type, iconEl) {
    var iconTypeClass = "".concat(ICON, "--").concat(type);
    iconEl.classList.add(iconTypeClass);
    var iconContent = ICON_CONTENTS[type];
    if (iconContent) {
        iconEl.innerHTML = iconContent;
    }
};
var initIcon = function (str, injectElIntoModal, atAnimationEnd) {
    if (!str)
        return;
    var iconEl = injectElIntoModal(iconMarkup);
    initPredefinedIcon(str, iconEl);
    setTimeout(atAnimationEnd, 700);
};
export default initIcon;
