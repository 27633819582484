function createGetProps($target, getExternalProps) {
  if (!$target) {
    $target = $(window);
  }

  return function getProps(timestamp) { // eslint-disable-line func-names
    const props = {
      scrollTop: Math.min(Math.max(0, $target.scrollTop()), 3000000),
      width: $target.width(),
      height: $target.height(),
    };

    return getExternalProps ? getExternalProps(props, timestamp) : props;
  };
}

app.subscribeAnimationFrame = subscribeAnimationFrame;

export default function subscribeAnimationFrame($target, prepareFrame, doWhile, getExternalProps) {
  const getProps = createGetProps($target, getExternalProps);
  let props = {};

  function handleFrame(timestamp) {
    const newProps = getProps(timestamp);

    if (shouldDoWhile(doWhile, newProps, timestamp)) {
      const changed = {};
      const anyChanged = getAnyChanged(changed, props, newProps);

      if (anyChanged) {
        prepareFrame(newProps, props, changed, timestamp);
        props = newProps;
      }
      window.requestAnimationFrame(handleFrame);
    }
  }

  window.requestAnimationFrame(handleFrame);
}

function shouldDoWhile(doWhile, newProps, timestamp) {
  return (typeof doWhile !== 'function') || doWhile(newProps, timestamp);
}

function getAnyChanged(changed, props, newProps) {
  let anyChanged = false;

  Object.keys(newProps).forEach(propertyKey => {
    changed[propertyKey] = newProps[propertyKey] !== props[propertyKey];
    if (!anyChanged && changed[propertyKey]) {
      anyChanged = true;
    }
  });

  return anyChanged;
}
