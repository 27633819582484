const { compact, isArray } = app.helpers;

export function filterUrl($form, omitEmptyValues = true, filterNames = null) {
  let parameters = $form.serializeArray();
  const action = $form.attr('action');

  parameters = parameters.filter(({ name }) => name !== 'utf8');
  if (omitEmptyValues) {
    parameters = parameters.filter(({ value }) => value != null && value !== '');
  }

  return changeUrlParameters(action, parameters, filterNames);
}

export function changeUrlParameters(url, setParameters, filterNames = null) {
  const urlParts = url.split('?');
  let parameters = getUrlParameters(url);

  if (filterNames) {
    parameters = parameters.filter(({ name }) => !filterNames.includes(name.replace(/\[]$/, '')));
  }
  if (!isArray(setParameters)) {
    setParameters = serializedArrayFromHash(setParameters);
  }
  parameters = prepareParameters(parameters.concat(setParameters));
  urlParts[1] = $.param(parameters);
  url = compact(urlParts).join('?');

  return url;
}

export function getUrlParameters(url) {
  const parametersPart = url.split('?')[1] || '';

  if (!parametersPart) {
    return [];
  }
  const parameters = parametersPart.split('&');

  return parameters.map(parameter => {
    const [name, value] = parameter.split('=').map(decodeURIComponent);

    return { name, value };
  });
}

function prepareParameters(serializedArray) {
  // collapsing value by names to last in order
  const parameters = [];
  const names = [];

  serializedArray.forEach(parameter => {
    const index = /\[]$/.test(parameter.name) ? -1 : names.indexOf(parameter.name);

    if (index < 0) {
      names.push(parameter.name);
      parameters.push(parameter);
    } else {
      parameters[index] = parameter;
    }
  });

  return parameters.filter(parameter => parameter.value !== '').sort(serializedSort);
}

function serializedSort(a, b) {
  if (a.name === b.name) {
    return a.value >= b.value ? 1 : -1;
  }

  return a.name > b.name ? 1 : -1;
}

function serializedArrayFromHash(parameters) {
  return Object.keys(parameters).map(name => ({ name, value: parameters[name] })).sort(serializedSort);
}

export function hashFromSerializedArray(serializedArray, reduceObjectName = false) {
  return serializedArray.reduce((hash, { name, value }) => {
    let hashName = name.replace(/\[]$/, '');
    const singleValue = hashName === name;

    if (reduceObjectName) {
      hashName = hashName.replace(/^\w+\[(\w+)]$/, '$1');
    }
    if (singleValue) {
      hash[hashName] = value;
    } else if (hashName in hash) {
      hash[hashName].push(value);
    } else {
      hash[hashName] = [value];
    }

    return hash;
  }, {});
}

app.shared = {
  lib: {
    url: {
      filterUrl,
      changeUrlParams: changeUrlParameters,
      getUrlParams: getUrlParameters,
      serializedArrayFromHash,
      hashFromSerializedArray,
    },
  },
};
