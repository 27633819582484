import { Popover } from 'bootstrap';
import './index.scss';

const { getTitle } = Popover.prototype;

Popover.prototype.getTitle = function() {
  if (!this.config.html) {
    this.config.html = true;
  }

  return getTitle.call(this).replace(/\n/g, '<br />');
};

app.listenToElement('[data-bs-toggle="popover"]', $popover => {
  $popover.each((_, el) => {
    const options = {
      trigger: 'focus',
      sanitize: false,
    };

    if (el.hasAttribute('data-bs-content-id')) {
      options.content = document.querySelector(`#${el.getAttribute('data-bs-content-id')}`).innerHTML;
      options.html = true;
    }

    // eslint-disable-next-line no-new
    new Popover(el, options);
  });
});
