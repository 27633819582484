export default {
  numberWithPrecision,
  numberWithDelimiter,
  numberToPercentage,
  numberToCurrency,
  parseNumberWithDelimiter,
  verySimpleFormat,
};

function numberWithPrecision(number, options = {}) {
  // eslint-disable-next-line camelcase
  const { delimiter, separator, precision, strip_insignificant_zeros } = { ...numberCurrencyFormat(), ...options };

  if (number === null || Number.isNaN(number)) {
    return '';
  }
  if (typeof number === 'string') {
    number = parseFloat(number);
  }
  number = number.toFixed(~~precision); // eslint-disable-line no-bitwise
  const [i, f] = number.split('.');
  // eslint-disable-next-line camelcase
  const decimals = getDecimals(strip_insignificant_zeros, separator, f);

  return `${i.replace(/(\d)(?=(?:\d{3})+$)/g, `$1${delimiter}`)}${decimals}`;
}

// eslint-disable-next-line camelcase
function getDecimals(strip_insignificant_zeros, separator, f) {
  // eslint-disable-next-line camelcase
  return Number.isNaN(f) || strip_insignificant_zeros && parseInt(f, 10) === 0 ? '' : separator + f;
}

function numberWithDelimiter(number, options = {}) {
  return numberToCurrency(number, { format: false, ...options });
}

function numberToPercentage(number, options = {}) {
  return numberToCurrency(number, { format: '%n%u', unit: '%', ...options });
}

function parseNumberWithDelimiter(value) {
  const formatOptions = numberCurrencyFormat();

  return app.helpers.isString(value) ? parseFloat(value
    .replace(new RegExp(`\\${formatOptions.delimiter}`, 'g'), '')
    .replace(new RegExp(`\\${formatOptions.separator}`), '.')) : value;
}

function numberCurrencyFormat() {
  return I18n.t('number.currency.format');
  // return {
  //   separator: '.',
  //   delimiter: ' ',
  //   precision: 2,
  //   strip_insignificant_zeros: true,
  //   significant: false,
  //   format: '%n %u',
  //   unit: 'руб.',
  // };
}

function numberToCurrency(number, options = {}) {
  const mergedOptions = { ...numberCurrencyFormat(), options };
  const n = numberWithPrecision(number, mergedOptions);

  return mergedOptions.format ? mergedOptions.format.replace('%n', n).replace('%u', mergedOptions.unit) : n;
}

function verySimpleFormat(text) {
  return text.replace(/\r\n?|\n\r?/g, '<br />');
}
