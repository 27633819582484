// class: 'js-autosubmit-form'[, data: { autosubmit_delay: 750 }]

app.listenEvents('change/dp.change   .js-autosubmit-form', $form => {
  const autosubmitTimeoutId = $form.data('autosubmitTimeoutId');

  if (autosubmitTimeoutId != null) {
    clearTimeout(autosubmitTimeoutId);
  }

  const delay = $form.data('autosubmitDelay') || 300;

  if (delay === 0) {
    $form.submit();
  } else {
    $form.data('autosubmitTimeoutId', setTimeout(() => {
      $form.submit();
    }, delay));
  }
});
