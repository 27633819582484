import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';

const { mapValues, includes, isArray, isPlainObject, isString } = app.helpers;

/**
 *
 * Creates component connected to redux with smart declaration of state props and dispatch rules.
 *
 * @param {React.Component} ComposedComponent - Component class to redux connect.
 * @param {String|Array|Object.<String, String|Function} stateKeys - State key name or array of names
 *                                                                   or hash for map to props from redux state.
 * @param {Object} [dispatchRules = null] - Map of dispatch rules.
 * @returns {React.Component} Returns component connected to redux.
 * @example
 *
 * export default connectToRedux(PropertyRoomSelect, ['room_variants', 'room_cart'], {
 *   dispatchAmount: (policy_id, amount) => {
 *     return { type: 'PATCH_room_cart', patch: { id: policy_id, amount } };
 *   },
 * });
 */

export default (ComposedComponent, stateKeys, dispatchRules = null) => connect(
  stateKeys && ((state, ownProps) => ({
    ...ownProps,
    ...(isPlainObject(stateKeys)
      // eslint-disable-next-line no-nested-ternary
      ? mapValues(stateKeys, (stateRule, key) => (isString(stateRule) ? (
        canGetFromStore(state[stateRule], ownProps[key]) ? state[stateRule] : ownProps[key]
      ) : stateRule(state, ownProps)))
      : pickBy(state, (value, key) => includes(stateKeys, key) && canGetFromStore(value, ownProps[key]))) })),
  dispatchRules && (dispatch => (
    mapValues(dispatchRules, action => (
      (...parameters) => dispatch(action(...parameters))
    ))
  )),
)(ComposedComponent);

function canGetFromStore(reduxValue, ownValue) {
  // console.log('canGetFromStore', reduxValue, ownValue);
  if (ownValue) {
    return checkValue(reduxValue, ownValue);
  }

  return true;
}

function isEmptyValue(value) {
  return value == null
    || (isArray(value) || isString(value)) && value.length === 0
    || isPlainObject(value) && Object.keys(value).length === 0;
}

function checkValue(reduxValue, ownValue) {
  if (isEmptyValue(reduxValue)) {
    return false;
  }
  if (isPlainObject(ownValue) && ownValue.id) {
    return ownValue.id === reduxValue.id;
  }

  return true;
}
