import waitFor from '../lib/waitFor';

let $modal;

app.listenEvents('page:leave', () => {
  // Close all shown modals for avoiding side-effects after returning to this page
  $('.modal.show').modal('hide');
  // If not all backgrops was removed
  $('.modal-backdrop').remove();
});

app.listenToElement('#global_modal', $m => { $modal = $m; });

app.listenEvents({
  'click   .js-link-to-modal': ($link, event) => {
    const content = $link.data('content');
    const title = $link.data('title') || $link.html();

    // If modal was opened from dropdown, close dropdown without waiting until modal view is loaded
    if ($link.hasClass('dropdown-item')) {
      $link.closest('.dropdown-menu').prev().dropdown('hide');
    }

    prepareOpenModalFromTrigger($link);
    if (title) {
      setTitle(title);
    }
    if (content) {
      setContent(content);
    } else {
      handleNoContent($link);
    }
    openModal();
    event.preventDefault();

    return false;
  },

  'shown.bs.modal    .modal': autofocusInModal,

  'hidden.bs.modal   .modal': $modalEl => {
    $modalEl.find('.modal-lg').removeClass('modal-lg');
    $modalEl.find('.modal-dialog-centered').removeClass('modal-dialog-centered');
    let $form = $modalEl.closest('form');

    if ($form.length === 0) {
      $form = $modalEl.find('form');
    }
    if ($form.length === 0) {
      return;
    }
    if ($form.data('lastSuccess')) {
      $form.data('lastSuccess', false);
      app.dom.remove($form.data('formResultIcon'));
      $form.removeData('formResultIcon');
      $form[0].reset();
    }
  },
});

function autofocusInModal($modalEl) {
  const $autofocusElement = $modalEl.find('[autofocus]');

  if ($autofocusElement.length > 0) {
    $autofocusElement.focusToEnd();
  }
}

function loadContentFromUrl(url) {
  setContent(app.progressBar.template(100));
  $.ajax({
    url,
    success: (ajaxContent, status, xhr) => {
      const ajaxTitle = xhr.getResponseHeader('X-Title');

      if (ajaxTitle) {
        setTitle(decodeURIComponent(ajaxTitle.replace(/\+/g, '%20')));
      }
      setContent(ajaxContent);
    },
    error: closeModal,
    dataType: 'html',
  });
}

function prepareOpenModalFromTrigger($trigger) {
  // const $dropDown = $trigger.closest('.dropdown-menu');
  // if ($dropDown.length > 0) {
  //   $dropDown.dropdown('toggle');
  // }
  if ($trigger.attr('data-modal-centered') !== undefined) {
    $modal.find('.modal-dialog').addClass('modal-dialog-centered');
  }
  if ($trigger.attr('data-modal-lg') !== undefined) {
    $modal.find('.modal-dialog').addClass('modal-lg');
  }
  if ($trigger.closest('.modal').length === 0) {
    $modal.data('$trigger', $trigger);
  }
}

function openModal() {
  $modal.modal('show');
}

function closeModal() {
  const modal = app.modal.getModal().data('bs.modal');

  waitFor(
    () => !modal._isTransitioning, // eslint-disable-line no-underscore-dangle
    () => $modal.modal('hide'),
  );
}

function setTitle(title) {
  if ($modal) {
    $modal.find('.modal-title').html(title);
  }
}

function setContent(content) {
  let $content = $(content);

  // Unwrap .row > .col > *
  if ($content.length === 1 && $content.children().length === 1 && $content.hasClass('row')) {
    $content = $content.children().children();
  }
  const $submit = $content.find(':submit');

  if ($submit.length > 0) {
    $submit.removeClass('w-100');
    makeFooter($submit);
  }
  $modal.find('.modal-body').html($content);
  autofocusInModal($modal);
}

function makeFooter($submit) {
  let $footer = $submit.parent();

  if ($footer.is('form')) {
    $submit.wrap('<div class="modal-footer"></div>');
    $footer = $submit.parent();
  } else {
    $footer.siblings().each$($sibling => {
      const colClasses = app.helpers.filter($sibling[0].classList,
        className => /col-\w{2}-\d{1,2}/.test(className)).join(' ');

      if (colClasses) {
        $sibling.removeClass(colClasses).addClass('form-group');
      }
    });
    $footer.attr('class', 'modal-footer').appendTo($footer.closest('form'));
  }
  $footer.prepend('<button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>');
}

function getModal() {
  return $modal;
}

function handleNoContent($link) {
  const url = $link.data('href') || $link.attr('href');

  if (url) {
    loadContentFromUrl(url);
  } else {
    app.error('.js-link-to-modal without url!');
  }
}

app.modal = {
  openModal,
  closeModal,
  setTitle,
  setContent,
  getModal,
};
