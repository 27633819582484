import React from 'react';
import { Provider } from 'react-redux';

/*
 *  Export a function that returns a ReactComponent, depending on a store.
 *  This is used for the client rendering hook after the page html is rendered.
 *  React will see that the state is the same and not do anything.
 */
export default function(ComposedComponent, displayName = null) {
  const component = ownProps => (
    <Provider store={app.redux.getStore()}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ComposedComponent {...ownProps} />
    </Provider>
  );

  if (displayName) {
    component.displayName = displayName;
  }

  return component;
}
