app.listenToElement('.g-rating-stars-input :radio:checked', updateStars);

app.listenEvents('change   .g-rating-stars-input :radio', updateStars);

function updateStars($checkedRadio) {
  const $star = $checkedRadio.closest('.star');

  $star.nextAll('.star').removeClass('active');
  $star.prevAll('.star').add($star).addClass('active');
}

app.listenEvents('reset   form', $form => {
  $form.find('.g-rating-stars-input .star.active').removeClass('active');
});
