import React from 'react';
import { createRoot } from 'react-dom/client';

app.listenToElement(
  '[data-react-component]',
  mountComponentToElement,
  unmountComponentFromElement,
);

const registeredComponents = {};

export function registerComponents(components) {
  Object.assign(registeredComponents, components);

  if (!app.redux.getStore()) {
    return;
  }

  $('[data-react-component]').each$($element => {
    const domNode = $element[0];
    const name = domNode.getAttribute('data-react-component');

    if (name in components) {
      renderComponentAtNode(domNode, components[name]);
    }
  });
}

function mountComponentToElement($element) {
  const domNode = $element[0];
  const name = domNode.getAttribute('data-react-component');

  if (!name) {
    app.error('No component name in', domNode);

    return;
  }

  const componentClass = registeredComponents[name];

  if (!componentClass) {
    app.log('info', 'No registered component class with name', name);

    return;
  }

  renderComponentAtNode(domNode, componentClass);
}

function renderComponentAtNode(domNode, componentClass) {
  const propsString = domNode.getAttribute('data-props');
  const props = propsString ? JSON.parse(propsString) : null;
  const component = React.createElement(componentClass, props);

  if (!domNode.reactRoot) {
    domNode.reactRoot = createRoot(domNode);
  }

  domNode.reactRoot.render(component);
}

function unmountComponentFromElement($element) {
  const domNode = $element[0];

  if (domNode.reactRoot) {
    domNode.reactRoot.unmount();
    delete domNode.reactRoot;
  }
}
