$.fn.nearestFind = function(selector, extremeEdgeSelector = 'form, body') {
  if (this.length === 0) {
    return this;
  }
  let $edge = this;
  let $result = $edge.find(selector).not(this);

  while (!$result.length && $edge.length && !$edge.is(extremeEdgeSelector)) {
    $edge = $edge.parent();
    $result = $edge.find(selector).not(this);
  }

  return $result;
};

$.fn.getFileName = function() {
  const fileName = this.val();
  const matches = fileName.match(/(.+\\)?(.+)$/);

  return matches && matches[2] || fileName;
};
