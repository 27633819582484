export default function waitFor(condition, success, error = undefined, waitTime = 1000, microSleep = 16 * 3) {
  const waitUntil = performance.now() + waitTime;

  ((function loop() {
    if (condition()) {
      success();

      return;
    }
    if (performance.now() < waitUntil) {
      setTimeout(loop, microSleep);
    } else if (error) {
      error();
    }
  })());
}
