import { enterDateRangeField } from './date-range';
import { enterFlatpickrField } from './flatpickr';

const flatpickrInputTypes = ['date', 'time', 'datetime-local', 'month'];

app.listenToElement(flatpickrInputTypes.map(inputType => `input[type="${inputType}"]:not(.js-react)`)
  .join(', '), $input => {
  const attributeType = $input.attr('type');

  if ($input.prop('type') !== attributeType) {
    enterFlatpickrField($input);
  } else if (attributeType === 'date' && $input.attr('data-range')) {
    enterDateRangeField($input);
  }
});
