$.fn.select2.amd.define('select2/addInfiniteScrollToResultsAdapter',
  [
    'select2/utils',
    'select2/dropdown/infiniteScroll',
  ],
  // eslint-disable-next-line func-names
  (Utils, InfiniteScroll) => function addInfiniteScrollToResultsAdapter(resultsAdapter) {
    return Utils.Decorate(
      resultsAdapter,
      InfiniteScroll,
    );
  });
