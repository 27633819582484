import './comments';
import './g-rating-stars-input';
import './label-float';
import './offline-alert';
import './pagination';
import Slot from './slot';
import SuperFastSearch from './super-fast-search';

[
  Slot,
  SuperFastSearch,
].forEach(app.registerView.bind(app));
