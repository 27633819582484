import SlotsTable from '../erp/slots-table';

export default class Overplanned extends SlotsTable {
  static selector = '.js-overplanned';
  static events = {
    'ajax:success             form': 'handleAjaxSuccessForm',
    'change/input               tr': 'handleChangeTr',
    'cocoon:after-remove      form': 'handleRemoveTr',
  };

  initialize() {
    super.initialize();
  }

  handleAjaxSuccessForm($overplanned, event, data) {
    if (data['overplanned_user_html']) {
      $overplanned.replaceWith(data['overplanned_user_html']);
    } else {
      app.refreshPage();
    }
  }

  handleRemoveTr($form, _event, _$nestedFields) {
    super.toggleSaveButtonFor($form);
  }

  toggleSaveButtonFor($element) {
    super.toggleSaveButtonFor($element);
  }

  handleChangeTr($row) {
    super.handleChangeTr($row);
  }
}
