import isNumber from 'lodash/isNumber';

const lazyImagesPool = new app.AnimationFramePool(showLazyImage);

app.listenToElement('[data-image-url][data-lazy]', $image => {
  const lazy = $image.data('lazy');

  if (lazy === 'auto') {
    showLazyImage($image);
  } else if (isNumber(lazy)) {
    setTimeout(showLazyImage.bind(null, $image), lazy);
  } else if (lazy !== 'manual') {
    lazyImagesPool.push($image);
  }
});

function showLazyImage($image) {
  $image.css({ backgroundImage: `url('${$image.data('imageUrl')}')` });
  $image.removeAttr('data-lazy');
  $image.removeAttr('data-image-url');
}
