import redirectOnSuccess from './ujs';
import { tryReplaceTr } from './ujsTableHelpers';

app.listenEvents('table tbody td > [data-remote][data-method="delete"]', {
  'ajax:beforeSend': $control => {
    $control.closest('tr').addClass('table-warning');
  },

  'ajax:success': ($control, _event, data, _status, xhr) => {
    if (data && data['location']) {
      app.visit(data['location']);
    }

    if (data && data['el'] && data['html']) {
      $(data['el']).replaceWith(data['html']);
    }

    const $tr = $control.closest('tr');
    const siblingsCount = $tr.siblings('tr').length;

    $tr.remove();
    if (siblingsCount === 0) {
      redirectOnSuccess($control, xhr);
    }
  },
});

app.listenEvents('table', {
  // 'ajax:beforeSend': $control => {
  //   $control.closest('tr').addClass('bg-warning');
  // },

  'ajax:success': ($table, event, data, _status, _xhr) => {
    tryReplaceTr($(event.target), data);
  },
});
