import max from 'lodash/max';
import subscribeAnimationFrame from '../lib/subscribeAnimationFrame';

app.listenToElement('.table-responsive-shades', $shades => {
  const $responsive = $shades.children('.table-responsive');
  const $tables = $responsive.find('.table');

  app.dom.memoryData($shades[0], 'mounted', true);
  const shade = {
    left: false,
    right: false,
  };

  subscribeAnimationFrame($shades,
    ({ scrollLeft, tableWidth, width }, props, changed) => {
      if (changed.scrollLeft) {
        const shadeLeft = scrollLeft > 10;

        if (shadeLeft !== shade.left) {
          shade.left = shadeLeft;
          $shades.toggleClass('with-shade-left', shadeLeft);
        }
      }
      if (changed.tableWidth || changed.width || changed.scrollLeft) {
        const shadeRight = (tableWidth - width - scrollLeft) > 10;

        if (shadeRight !== shade.right) {
          shade.right = shadeRight;
          $shades.toggleClass('with-shade-right', shadeRight);
        }
      }
    },
    () => app.dom.memoryData($shades[0], 'mounted'),
    newProps => ({
      ...newProps,
      scrollLeft: $responsive.scrollLeft(),
      tableWidth: max($tables.map$($t => $t.width())),
    }));
}, $shades => {
  app.dom.memoryData($shades[0], 'mounted', false);
});
