import { hashFromSerializedArray } from '../lib/url';

app.listenToPage(null, $page => {
  const $fipForms = $page.find('.fip-input > .fip-form:not([data-fip-form])');

  $fipForms.each$($fipForm => {
    $fipForm.parent().text($fipForm.prev('.fip-display').text());
  });
});

app.listenEvents('.fip-input', {
  click: fipActivate,

  blur($fip) {
    $fip.removeClass('fip-active');
  },

  keydown($fip, event) {
    if (event.keyCode === 27) {
      const { $fipForm, fipName, fipValue } = $fip.data();
      const $fields = $fipForm.find(':input[name]');
      const $fipFields = app.forms.fieldsFor($fields, fipName);

      setValue($fipFields, fipValue);
      $fip.removeClass('fip-active');
    }
  },

  change($fip, event) {
    const content = contentFromField($(event.target));

    displayFip($fip, content.length > 0 ? content : $fip.data('fipPlaceholder') || '—');
    $fip.data('$fipForm').submit();
  },

  'focus/input   input[type="text"]': $input => {
    // autosize-x
    $input.attr('size', Math.max(1, $input.val().length));
  },

  'ajax:beforeSend': $fip => { // eslint-disable-line consistent-return
    if ($fip.hasClass('fip-loading')) {
      return false;
    }
  },

  'ajax:send': $fip => {
    $fip.removeClass('fip-active').addClass('fip-loading');
  },

  'ajax:complete': $fip => {
    $fip.removeClass('fip-loading');
  },

  'ajax:success': $fip => {
    const a = hashFromSerializedArray($fip.data('$fipForm').serializeArray(), true);
    const fipValue = a[$fip.data('fipName')];

    $fip.data('fipValue', fipValue);
    $fip.attr('data-fip-value', fipValue);
    $fip.data('fipContent', $fip.data('$fipDisplay').html());
  },

  'ajax:error': $fip => {
    displayFip($fip, $fip.data('fipContent'));
  },
});

function fipActivate($fip) {
  const $fipForm = $fip.data('$fipForm');

  if ($fipForm) {
    if ($fip.hasClass('fip-active')) {
      return;
    }
  } else if (!fipConstruct($fip)) {
    return;
  }
  $fip.addClass('fip-active');
  app.forms.focusField($fip.data('$fipFields'));
}

function buildFormClone($fip) {
  const { fipForm, fipName, fipValue, fipId, fipNestedIds } = $fip.data();
  const selector = `.fip-form[data-fip-form="${fipForm}"]`;
  const nearestForm = $fip.parent().find(selector);
  const $fipForm = (nearestForm.length > 0 ? nearestForm : $(selector)).clone();

  if ($fipForm.length === 0) {
    app.error(`No .fip-form[data-fip-form="${fipForm}"] for field "${fipName}"!`);

    return false;
  }

  const $fields = $fipForm.find(':input[name]');
  const $fipFields = app.forms.fieldsFor($fields, fipName);

  if ($fipFields.length === 0) {
    app.error(`No inputs in .fip-form[data-fip-form="${fipForm}"] for field "${fipName}"!`);

    return false;
  }

  $fields.not('[name="_method"]').not($fipFields).remove();
  setValue($fipFields, fipValue);

  const fipNames = fipName.split('.');

  fipNames.pop();
  fipNames.reduce((prevName, nestedName) => {
    const nestedFullName = `${prevName}[${nestedName}_attributes]`;

    $(`<input type="hidden" name="${nestedFullName}[id]" value="${fipNestedIds[nestedName]}" />`).appendTo($fipForm);

    return nestedFullName;
  }, fipForm);

  $fipForm.attr('action', $fipForm.attr('action').replace(':id', fipId))
    .removeAttr('data-fip-form')
    .removeData('fipForm');

  return { $fipForm, $fipFields };
}

function fipConstruct($fip) {
  const fipFormAndFields = buildFormClone($fip);

  if (!fipFormAndFields) {
    return false;
  }

  const { $fipForm, $fipFields } = fipFormAndFields;

  const fipContent = $fip.html();
  const $fipDisplay = $(`<div class="fip-display mw-100">${fipContent}</div>`);

  $fip.html($fipDisplay);

  $fipForm
    .appendTo($fip);
  $fip
    .data('fipContent', fipContent)
    .data('$fipDisplay', $fipDisplay)
    .data('$fipForm', $fipForm)
    .data('$fipFields', $fipFields);

  return true;
}

function contentFromField($input) {
  const value = $input.val();

  if ($input.is('select')) {
    return $input.find(`option[value="${value}"]`).text();
  }
  switch ($input.attr('type')) {
    case 'checkbox':
    case 'radio':
      return $input.next('label').html();
    default:
      return value;
  }
}

function displayFip($fip, content) {
  $fip.data('$fipDisplay').html(content);
}

function setValue($field, value) {
  $field.val(value);
}
