$.fn.select2.amd.define('select2/createCustomDropdownAdapter',
  [
    'select2/utils',
    'select2/dropdown',
    'select2/dropdown/search',
    'select2/dropdown/attachBody',
    'select2/dropdown/minimumResultsForSearch',
    'select2/dropdown/closeOnSelect',
  ],
  (Utils,
    Dropdown, DropdownSearch,
    AttachBody, MinimumResultsForSearch, CloseOnSelect) => {
    const CustomAdapter = function(...arguments_) {
      CustomAdapter.__super__.constructor.call(this, ...arguments_);
    };

    Utils.Extend(CustomAdapter, Dropdown);

    return function createCustomDropdownAdapter(options) { // eslint-disable-line func-names
      let DecoratedCustomAdapter = CustomAdapter;

      DecoratedCustomAdapter = Utils.Decorate(DecoratedCustomAdapter, DropdownSearch);

      if (options.minimumResultsForSearch !== 0) {
        DecoratedCustomAdapter = Utils.Decorate(
          DecoratedCustomAdapter,
          MinimumResultsForSearch,
        );
      }

      if (options.closeOnSelect) {
        DecoratedCustomAdapter = Utils.Decorate(
          DecoratedCustomAdapter,
          CloseOnSelect,
        );
      }

      DecoratedCustomAdapter = Utils.Decorate(DecoratedCustomAdapter, AttachBody);

      return DecoratedCustomAdapter;
    };
  });
