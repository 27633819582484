import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../redux/resources/flashes';

const { connectToRedux } = app.redux;

const enterTransitionDuration = 350;
const exitTransitionDuration = 300;
const warmTransitionDuration = 50;

class Flashes extends React.Component {
  componentDidMount() {
    const { predefinedFlashes } = this.props;

    if (predefinedFlashes.length > 0) {
      this.containerElement.parentNode.removeAttribute('data-react-props');
      app.flash.auto(predefinedFlashes);
    }
  }

  handleClickClose(id) {
    app.flash.hide(id);
  }

  renderFlash = ({ id, type, text }) => (
    <CSSTransition
      key={id}
      classNames="flash"
      // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
      timeout={{
        enter: enterTransitionDuration + warmTransitionDuration,
        exit: exitTransitionDuration + warmTransitionDuration,
      }}
    >
      <div // eslint-disable-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
        className={classNames('flash', 'd-flex', type)}
        role="button"
      >
        <div
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
          dangerouslySetInnerHTML={{ __html: text }} // eslint-disable-line react/no-danger
        />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className="btn-close btn-close-white"
          // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
          onClick={this.handleClickClose.bind(this, id)}
        />
      </div>
    </CSSTransition>
  );

  render() {
    const { flashes } = this.props;

    return (
      // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
      <div className="b-flashes" ref={element => { this.containerElement = element; }}>
        <TransitionGroup
          // component="div"
          className="b-flashes__animation-layout"
        >
          {flashes.map(this.renderFlash)}
        </TransitionGroup>
      </div>
    );
  }
}

Flashes.propTypes = {
  predefinedFlashes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  // dispatch: PropTypes.func,
  flashes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['alert', 'notice', 'error', 'nightmare']),
    text: PropTypes.string,
  })),
};

Flashes.defaultProps = {
  predefinedFlashes: [],
  flashes: [],
};

export default connectToRedux(Flashes, ['flashes']);
