import throttle from 'lodash/throttle';

app.listenEvents({
  'input/change/keyup/paste   [data-auto-save]': changeAutoSaveField,
  'ajax:success   form[data-remote]': dropAutoSave,
});

app.listenToElement('[data-auto-save]', $field => {
  if (fieldCanRestore($field)) {
    const value = getStoredValue($field);

    if (value && value.length > 0) {
      setFieldValue($field, value);
      showStoredHint($field);
    }
  }
});

function changeAutoSaveField($field) {
  let throttledChange = $field.data('throttledChange');

  if (!throttledChange) {
    throttledChange = throttle(tryStore.bind(null, $field), 1000);
    $field.data('throttledChange', throttledChange);
  }
  throttledChange();
}

function dropAutoSave($root) {
  $root.find('[data-auto-save]').each$($field => {
    removeStoredValue($field);
    showStoredHint($field, true);
  });
}

function tryStore($field) {
  const storedValue = $field.data('storedValue');
  const value = getFieldValue($field);

  if (value !== storedValue) {
    setStoredValue($field, value);
    $field.data('storedValue', value);
    showStoredHint($field);
  }
}

function showStoredHint($field, onlyHide = false) {
  if ($field.attr('type') === 'checkbox') {
    return;
  }
  const $input = $field.closest('.input');
  const $old = $input.children('.hint.text-countdown');

  if ($old.length > 0) {
    $old.remove();
  }
  if (!onlyHide && !fieldCanRestore($field)) {
    $input.append('<span class="hint text-countdown">Saved recently</span>');
  }
}

function setStoredValue($field, value) {
  localStorage.setItem(storageKey($field), value);
}

function getStoredValue($field) {
  return localStorage.getItem(storageKey($field));
}

function removeStoredValue($field) {
  return localStorage.removeItem(storageKey($field));
}

function storageKey($field) {
  return `auto-save_${$field.data('autoSave')}`;
}

function fieldCanRestore($field) {
  return $field.attr('type') === 'checkbox' || $field.val().length === 0;
}

function setFieldValue($field, value) {
  const type = $field.attr('type');

  switch (type) {
    case 'checkbox':
      $field.prop('checked', value === 'true');

      return;
    default:
      $field.val(value);
  }
}

function getFieldValue($field) {
  const type = $field.attr('type');

  switch (type) {
    case 'checkbox':
      return String($field.prop('checked'));
    default:
      return $field.val();
  }
}
