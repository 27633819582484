import { Tooltip } from 'bootstrap';

const { getTitle } = Tooltip.prototype;

Tooltip.prototype.getTitle = function() {
  if (!this.config.html) {
    this.config.html = true;
  }

  return getTitle.call(this).replace(/\n/g, '<br />');
};

app.listenToElement('[data-bs-toggle="tooltip"]', $tooltip => $tooltip.tooltip({
  trigger: 'hover',
}));
