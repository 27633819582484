app.listenEvents('.pagination .page-item', {
  'ajax:send': $pageItem => {
    updatePagination($pageItem, true);
  },

  'ajax:error': $pageItem => {
    updatePagination($pageItem, false);
  },

  'ajax:success': ($pageItem, event, data) => {
    app.sayAboutPageLoading(false);
    app.replaceLocation($(event.target).attr('href'));
    const $content = $(data);
    const $newPagination = takeFromContent($content, '.pagination').first();

    $('.pagination').each$($p => { $p.replaceWith($newPagination.clone()); });
    const contentSelector = $pageItem.closest('.pagination').data('paginationContentSelector')
      || '.js-pagination-content';

    $(contentSelector).replaceWith(takeFromContent($content, contentSelector));
  },
});

function updatePagination($pageItem, isPageLoading) {
  app.sayAboutPageLoading(isPageLoading);
  $pageItem.closest('.pagination').addClass('disabled');
  $pageItem.addClass('active').siblings('.active').removeClass('active').addClass('.rollback-active');
}

function takeFromContent($content, selector) {
  const $part = $content.filter(selector);

  if ($part.length > 0) {
    return $part;
  }

  return $content.find(selector);
}
