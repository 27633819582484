export default class SlotsTable extends app.View {
  initialize() {
    this.$('form').each$($form => {
      $form.data('persistedData', $form.serialize());
    });
    this.$('form tr').each$($tr => {
      $tr.data('persistedData', this.serializeArea($tr));
    });
  }

  toggleSaveButtonFor($element) {
    const $form = $element.closest('form');
    const disabled = $form.data('persistedData') === $form.serialize();

    $form.find(':submit')
      .prop('disabled', disabled)
      .toggleClass('btn-secondary', disabled)
      .toggleClass('btn-success', !disabled);
  }

  serializeArea($area) {
    return $area.find(':input[name]:not(:disabled)').map$($input => $input.serialize()).get().join('&');
  }

  handleChangeTr($row) {
    $row.toggleClass('table-warning', $row.data('persistedData') !== this.serializeArea($row));
    this.toggleSaveButtonFor($row);
  }
}
