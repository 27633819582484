$.fn.select2.amd.require([
  'select2/utils',
  'select2/dropdown/search',
], (Utils, DropdownSearch) => {
  const prototypeBind = DropdownSearch.prototype.bind;

  DropdownSearch.prototype.bind = function(decorated, container, $container) {
    prototypeBind.call(this, decorated, container, $container);
    container.on('select', parameters => {
      if (this.options.get('tokenSeparators') || this.options.get('tokenizer')) {
        if (parameters.data.text === this.$search.val()) {
          this.$search.val('');
        }
      }
    });
  };
});
