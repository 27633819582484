app.listenEvents('.js-erp-comments', {
  'click   .js-comment-cancel': $cancel => {
    const $form = $cancel.closest('.js-edit-comment');

    $form.prev().show();
    $form.remove();

    return false;
  },

  'ajax:success   [data-method="delete"]': $control => {
    const $card = $control.closest('.card');

    $control.closest('.card').slideUp(400, () => {
      $card.remove();
    });
  },

  'ajax:success': ($comments, event, data) => {
    if (!(data && data.comment_html)) {
      return;
    }
    const $target = $(event.target);

    if ($target.hasClass('js-new-comment')) {
      $target[0].reset();
    }
    handleComments($comments, data);
  },
});

function handleComments($comments, data) {
  const $comment = $(data.comment_html);
  const $alreadyComments = $comments.find(`[data-comment-id="${$comment.data('commentId')}"]`);

  if ($comment.hasClass('js-edit-comment')) {
    $alreadyComments.hide().after($comment);
    $comment.find('textarea').focusToEnd();
  } else if ($alreadyComments.length > 0) {
    $alreadyComments.last().after($comment);
    $alreadyComments.remove();
  } else {
    $comments.find('.js-new-comment').after($comment.hide());
    $comment.slideDown(400);
  }
}
