import each from 'lodash/each';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

const { registerReducers, dispatchers } = app.redux;

registerReducers({ flashes: [] });

const flashDefaultLifeTime = 7000;
let flashIdCounter = 0;

function generateFlashId() {
  flashIdCounter += 1;

  return flashIdCounter;
}

let previousNightmareFlashId;

function newFlash(type, text, lifeTime) {
  const id = generateFlashId();

  if (type === 'nightmare') {
    if (previousNightmareFlashId) {
      hideFlash(previousNightmareFlashId);
    }
    previousNightmareFlashId = id;
  } else if (lifeTime > 0) {
    setTimeout((() => hideFlash(id)), lifeTime);
  }

  return {
    id,
    type,
    text,
  };
}

/**
 * @param {('alert' | 'notice' | 'error' | 'nightmare')} type
 * @param {string} text
 * @param {number} lifeTime
 */
function showFlash(type, text, lifeTime = flashDefaultLifeTime) {
  dispatchers.flashes.appendStore(newFlash(type, text, lifeTime));
}

function hideFlash(id) {
  dispatchers.flashes.remove(id);
}

function flashAuto(object) {
  if (isArray(object)) {
    object.forEach(([type, text]) => showFlash(type, text));
  } else if (isPlainObject(object)) {
    each(object, (type, text) => showFlash(type, text));
  } else {
    return false;
  }

  return true;
}

app.flash = {
  show: showFlash,
  hide: hideFlash,
  alert: (...arguments_) => showFlash('alert', ...arguments_),
  notice: (...arguments_) => showFlash('notice', ...arguments_),
  // error: (...args) => showFlash('error', ...args),
  auto: flashAuto,
};
