// copy of /node_modules/select2/src/js/select2/selection/allowClear.js:AllowClear.prototype._handleClear
// changed lines commented; changed arguments

export default function allowClearHandleClear(Utils, event_) {
  // Ignore the event if it is disabled
  if (this.options.get('disabled')) {
    return;
  }

  const $clear = this.$selection.find('.select2-selection__clear');

  // Ignore the event if nothing has been selected
  if ($clear.length === 0) {
    return;
  }

  event_.stopPropagation();

  const data = Utils.GetData($clear[0], 'data');

  const previousValue = this.$element.val();

  // MODIFIED: able to clear without placeholder
  this.$element.val(''); // this.placeholder.id);

  let unselectData = {
    data,
  };

  this.trigger('clear', unselectData);
  if (unselectData.prevented) {
    this.$element.val(previousValue);

    return;
  }

  const preventedElement = data.find(element => {
    unselectData = {
      data: element,
    };

    // Trigger the `unselect` event, so people can prevent it from being
    // cleared.
    this.trigger('unselect', unselectData);

    // If the event was prevented, don't clear it out.
    if (unselectData.prevented) {
      this.$element.val(previousValue);

      return true;
    }

    return false;
  });

  if (preventedElement) return;

  this.$element.trigger('change');

  // MODIFIED: commented
  // this.trigger('toggle', {});
}
