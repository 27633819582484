import allowClearHandleClear from './allowClearHandleClear';

$.fn.select2.amd.define('select2/selection/default/multiple', [
  'select2/utils',
  'select2/selection/multiple',
  'select2/selection/allowClear',
  'select2/selection/placeholder',
], (Utils, MultipleAdapter, AllowClear, Placeholder) => {
  const CustomAdapter = function($element, options) {
    CustomAdapter.__super__.constructor.call(this, $element, options);
    this.placeholder = Placeholder.prototype.normalizePlaceholder(null, options.get('placeholder'));
  };

  Utils.Extend(CustomAdapter, MultipleAdapter);

  CustomAdapter.prototype.bind = function(...arguments_) {
    CustomAdapter.__super__.bind.apply(this, arguments_);
    this.$selection.on('click', '.select2-selection__clear', allowClearHandleClear.bind(this, Utils));
  };

  CustomAdapter.prototype.update = function(data) {
    const $rendered = this.$selection.find('.select2-selection__rendered');

    if (this.$element.data('as') === 'counted') {
      this.clear();
      $rendered.append(data.length === 0 ? placeholderTemplate(this) : summaryTemplate(this, data));
    } else {
      CustomAdapter.__super__.update.call(this, data);
      $rendered.addClass('as-tags');
      if (data.length === 0) {
        $rendered.prepend(placeholderTemplate(this));
      }
    }
    if (this.options.get('allowClear') && data.length > 0) {
      $rendered.addClass('allow-clear');
      const $remove = $('<span class="select2-selection__clear">×</span>');

      Utils.StoreData($remove[0], 'data', data);
      $rendered.append($remove);
    }
  };

  return CustomAdapter;
});

function placeholderTemplate(adapter) {
  return `<li class="select2-selection__placeholder">${adapter.options.get('placeholder') || ''}</li>`;
}

function summaryTemplate(adapter, data) {
  return `<li class="select2-selection__summary">${resultText(adapter, data)}</li>`;
}

function resultText(adapter, data) {
  if (data.length === 1) {
    return data[0].text;
  }
  if (data.length === adapter.$element.children('option').length) {
    return 'Все';
  }

  return `Выбрано (${data.length})`;
}
