export function tryReplaceEl(data) {
  if (data) {
    if (data['el'] && data['html']) {
      $(data['el']).replaceWith(data['html']);
    }
  }
}

export function tryReplaceTr($target, data) {
  if (data) {
    if (data['tr']) {
      replaceTableElement($target, data['tr'], 'tr');
    } else if (data['td']) {
      replaceTableElement($target, data['td'], 'td');
    }
  }
}

export function tryAddTr($target, data) {
  if (data && data['tr']) {
    const $tbody = $('table > tbody');

    if ($tbody.length === 1) {
      applyTr(data['tr'], $newTr => {
        $tbody.prepend($newTr);
      });
    } else {
      app.error('`tr` in response json of create request, but no single table on page!');
      app.refreshPage();
    }
  }
}

function replaceTableElement($target, html, tagName) {
  const $t = $target.closest(tagName);

  if ($t.length > 0) {
    applyTr(html, $newT => { $t.replaceWith($newT); });
  } else {
    app.error(`'${tagName}' in response json request, but not in ${tagName} of table on page!`);
  }
}

function applyTr(trHtml, callback) {
  const $newTr = $(trHtml);

  $newTr.addClass('table-success');
  callback($newTr);
  setTimeout(() => {
    $newTr.removeClass('table-success');
  }, 3000);
}
