import Normas from 'normas';
import Turbolinks from 'turbolinks';
import normasViews from 'normas/dist/js/extensions/views';
import normasTurbolinks from 'normas/dist/js/integrations/turbolinks';
import helpers from './helpers';

class EvroneErpNormas extends normasTurbolinks(normasViews(Normas)) {
  listenToElement(selector, enter, leave = null, options = {}) {
    super.listenToElement(selector, enter, leave, options);
    if (this.navigationStarted) {
      const $elements = this.constructor.contentElements(this.$page(), selector);

      if ($elements.length > 0) {
        this.handleElements($elements, selector, enter, 'enter', options.silent || false);
      }
    }
  }
}

const app = new EvroneErpNormas({
  Turbolinks,
  debugMode: process.env.NODE_ENV === 'development', // default `true`
  logging: {
    // hideInstancesOf: [Element, $],
    construct: false,
    // content: true,
    // eventsTable: true,
    navigationGrouping: true,
  },
  viewOptions: { logging: { construct: true, constructGrouping: 'groupCollapsed' } },
});

Object.assign(Normas.helpers, helpers);

// https://github.com/evrone/normas#-usage-and-project-structure
global.app = app;
