$.fn.select2.amd.define('select2/customResultsAdapter',
  [
    'select2/utils',
    'select2/results',
  ],
  (Utils, Results) => {
    const CustomAdapter = function($element, options, dataAdapter) {
      CustomAdapter.__super__.constructor.call(this, $element, options, dataAdapter);
    };

    Utils.Extend(CustomAdapter, Results);

    // Fix: MultiSelect with closeOnSelect:false - Scrolls to the top of list on each selection
    // https://github.com/select2/select2/issues/4417
    CustomAdapter.prototype.highlightFirstItem = function() {
      const $options = this.$results.find('.select2-results__option[aria-selected]');
      const $selected = $options.filter('[aria-selected=true]');

      // Check if there are no any selected options
      if ($selected.length === 0) {
        CustomAdapter.__super__.highlightFirstItem.call(this);
      }
    };

    return CustomAdapter;
  });
