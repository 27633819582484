import ClipboardJS from 'clipboard';

app.listenToElement('.js-clipboard', $element => {
  const clipboard = new ClipboardJS($element[0]);

  const prevContent = $element.html();
  const successContent = $element[0].dataset.clipboardCopied || 'Copied!';

  clipboard.on('success', event => {
    if (event.action === 'copy') {
      $element.html(successContent);

      setTimeout(() => {
        $element.html(prevContent);
      }, 500);
    }
  });
});
