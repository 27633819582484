$.fn.select2.amd.define('select2/data/customAjaxAdapter', [
  'select2/data/ajax',
  'select2/utils',
], (AjaxAdapter, Utils) => {
  const CustomAjaxAdapter = function($element, options) {
    CustomAjaxAdapter.__super__.constructor.call(this, $element, options);
  };

  Utils.Extend(CustomAjaxAdapter, AjaxAdapter);

  CustomAjaxAdapter.prototype.current = function(callback) {
    const data = [];

    this.$element.find(':selected').each$($option => {
      const option = this.item($option);

      data.push(select2GetState(option, this.$element));
    });
    callback(data);
  };

  return CustomAjaxAdapter;
});

function select2GetState(state, $element) {
  const initialState = $element.data('initialState');

  return (initialState && parseInt(state.id, 10) === parseInt(initialState.id, 10)) ? initialState : state;
}

app.select2GetState = select2GetState;
