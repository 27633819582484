// https://gist.github.com/volonterx/d25f624cfcca86e3e9db

// Usage: link_to …, prompt: { message: 'Some message', default: 'default value', param: 'name of parameter' }
// The prompt will ask for "message" and use "default" as the default value.
// Unless user selects cancel, "param"=<new value> will be sent to the given path.
// Optionally, you can just use `prompt: "message"`.

$.rails.prompt = function(message, defaultValue) {
  return window.prompt(message, defaultValue); // eslint-disable-line no-alert
};

$.rails.handlePrompt = function(element) {
  const config = element.data('prompt');
  const message = config.message || config;

  if (!message) {
    return true;
  }
  let value;
  let callback;

  if ($.rails.fire(element, 'prompt')) {
    const defaultValue = config.default;

    value = $.rails.prompt(message, defaultValue);
    if (value === null) {
      return; // eslint-disable-line consistent-return
    }
    callback = $.rails.fire(element, 'prompt:complete', [value]);
  }
  setParameterValue(element, config.param, value);

  return callback;
};

function setParameterValue(element, parameter = 'value', value) {
  if (element.is('form')) {
    setFormValue(element, parameter, value);
  } else {
    setNonFormValue(element, parameter, value);
  }
}

function setFormValue(element, parameter, value) {
  let $field = element.find(`:input[name="${parameter}"]`);

  if ($field.length === 0) {
    $field = $(`<input type="hidden" name="${parameter}" value="${value}" />`).appendTo(element);
  }
  $field.val(value);
}

function setNonFormValue(element, parameter, value) {
  if (element.data('remote') == null) {
    const querySymbol = /\?.+=/.test(element.attr('href')) ? '&' : '?';

    element.attr('href', `${element.attr('href') + querySymbol + parameter}=${value}`);
  }
  const parameters = element.data('params') || {};

  parameters[parameter] = value;
  element.data('params', parameters);
}

const { allowAction } = $.rails;

$.rails.allowAction = function(element) {
  if (element.data('prompt')) {
    return $.rails.handlePrompt(element);
  }

  return allowAction(element);
};
