import 'jquery.scrollto';

const wrapperSelector = '.form-group';
const focusWrapperSelector = [wrapperSelector, '.info-row', '.row', '.card-body', '.card', 'form'].join(', ');
const inputWithErrorClass = 'is-invalid';
const inputsWithErrorSelector = `:input.${inputWithErrorClass}`;
const errorMessageClass = 'invalid-feedback';
const limitErrors = 1;

function fieldsFor($fields, fieldName) {
  const fieldNamePart = fieldName.replace('.', '(_attributes)?\\](\\[\\d*\\])?\\[');
  const regxpName = new RegExp(`\\w+\\[${fieldNamePart}(_id)?\\]|^${fieldNamePart}$`);

  return $fields.filter$($field => regxpName.test($field.attr('name')));
}

function fieldCanError($field, $form = undefined) {
  const type = $field.attr('type');

  switch (type) {
    case 'hidden':
      return $field.hasClass('flatpickr-input');
    case 'checkbox':
      return false; // !$field.prop('checked');
    case 'radio':
      return !($form || $field.closest('form')).find(`input[name="${$field.attr('name')}"]:checked`).length;
    default:
      return type || $field.prop('tagName'); // /^\s*$/.test($field.val())
  }
}

function fieldValuePresent($field, $form = undefined) {
  const type = $field.attr('type');

  switch (type) {
    case 'hidden':
    case 'checkbox':
      return true;
    case 'radio':
      return ($form || $field.closest('form')).find(`input[name="${$field.attr('name')}"]:checked`).length > 0;
    default:
      return !/^\s*$/.test($field.val());
  }
}

function fillErrors($form, errors) {
  const $fields = $form.find('[name]:input').filter$($field => fieldCanError($field, $form));
  let result = false;

  Object.keys(errors).forEach(fieldName => {
    const fieldErrors = errors[fieldName];

    if (!fieldErrors || fieldErrors.length === 0) {
      return;
    }
    const $filtered = fieldsFor($fields, fieldName);

    if ($filtered.length > 0) {
      setErrors($filtered, fieldErrors);
      result = true;
    }
  });
  focusFirstErrorField($form);

  return result;
}

function setErrors($fields, errors) {
  $fields.add($fields.siblings('label, .flatpickr-input, .editor-toolbar, .CodeMirror')).addClass(inputWithErrorClass);
  const $wrappers = $fields.closest(wrapperSelector);

  $wrappers.children(`.${errorMessageClass}`).remove();
  if (app.helpers.isString(errors)) {
    errors = [errors];
  }
  if (errors && errors.length > 0) {
    const messages = errors
      .slice(0, limitErrors)
      .map(error => `<div class="${errorMessageClass}">${error}</div>`).join('');

    $wrappers.each$($wrapper => {
      if ($wrapper.find('.js-md-editor').length > 0) {
        const $mdEditorStatusBar = $wrapper.find('.editor-statusbar');

        $mdEditorStatusBar.prepend(messages);

        return;
      }
      const $hint = $wrapper.find('.form-text:first');

      if ($hint.length > 0) {
        $hint.before(messages);
      } else {
        $wrapper.append(messages);
      }
    });
  }
}

function clearErrors($target) {
  $target.removeClass('was-validated');
  $target.find(`.${errorMessageClass}`).remove();
  $target.find(`.${inputWithErrorClass}`).removeClass(inputWithErrorClass);
}

function allInvalid($form) {
  const $fields = $form.find('[name]:input').filter$($field => fieldCanError($field, $form));

  setErrors($fields);
  focusFirstErrorField($form);
}

const scrollingDurationFactor = 20;
let focusing = false;

function focusFirstErrorField($form) {
  const $field = $form.find(`${inputsWithErrorSelector}:first`);

  if ($field.length === 0) {
    return;
  }
  if (focusing) {
    return;
  }
  focusing = true;
  proceedFocusing($field);
}

function proceedFocusing($field) {
  const $focusWrapper = $field.closest(focusWrapperSelector);
  const $scrollTarget = $focusWrapper.length > 0 ? $focusWrapper : $field;
  const $scrollable = $(window);
  let [hideSide, overlap] = getPositionSide($scrollTarget, $scrollable);// eslint-disable-line prefer-const

  if (hideSide) {
    overlap = Math.round(overlap + ($scrollable.height() - $scrollTarget.outerHeight()) / 2);
    const duration = Math.sqrt(overlap) * scrollingDurationFactor;

    $scrollable.scrollTo(`${hideSide === 'top' ? '-' : '+'}=${overlap}px`, duration, () => {
      focusField($field);
    });
  } else {
    focusField($field);
  }
}

function focusField($field) {
  if ($field.hasClass('js-md-editor')) {
    const easyMDE = app.dom.memoryData($field[0], 'easyMDE');

    easyMDE.codemirror.focus();
  } else if ($field.attr('type') !== 'file') {
    $field.focusToEnd();
  }
  focusing = false;
}

function getPositionSide($element, $scrollable) {
  const elementHeight = $element.outerHeight();
  const scrollTop = $scrollable.scrollTop();
  const height = $scrollable.height();
  const lineTop = topPosition($element);
  const topOverlap = scrollTop - lineTop;

  if (topOverlap >= 0) {
    return ['top', topOverlap];
  }
  const bottomOverlap = (lineTop + elementHeight) - (scrollTop + height);

  if (bottomOverlap >= 0) {
    return ['bottom', bottomOverlap];
  }

  return [false, 0];
}

function topPosition($node) {
  return $node.offset().top;
}

app.forms = {
  fieldsFor,
  fieldCanError,
  fieldValuePresent,
  fillErrors,
  setErrors,
  clearErrors,
  allInvalid,
  focusFirstErrorField,
  focusField,
};
