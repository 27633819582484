import subscribeAnimationFrame from './subscribeAnimationFrame';

class AnimationFramePool {
  pool = [];

  constructor(activateItem, muted = undefined, radius = 0) {
    this.activateItem = activateItem;
    this.muted = muted;
    this.radius = radius;
    this.prepareFrame = this.prepareFrame.bind(this);
  }

  push($item) {
    this.pool.push($item);
    if (this.pool.length === 1) {
      subscribeAnimationFrame($(window), this.prepareFrame, () => this.pool.length > 0);
    }
  }

  prepareFrame(props) {
    if (this.muted && this.muted()) {
      return;
    }
    const scrollTop = props.scrollTop - this.radius;
    const scrollBottom = props.scrollTop + props.height + this.radius;
    const doneIndexes = this.getDoneIndexes(scrollTop, scrollBottom);

    if (doneIndexes.length > 0) {
      this.pool = this.pool.filter((_item, index) => !app.helpers.includes(doneIndexes, index));
    }
  }

  getDoneIndexes(scrollTop, scrollBottom) {
    return this.pool.reduce((indexes, $item, index) => {
      if (!app.dom.contains(app.el, $item[0])) {
        indexes.push(index);
      } else {
        const itemTop = $item.offset().top;
        const itemBottom = itemTop + $item.outerHeight();

        if (scrollBottom >= itemTop && itemBottom >= scrollTop) {
          this.activateItem($item);
          indexes.push(index);
        }
      }

      return indexes;
    }, []);
  }
}

app.AnimationFramePool = AnimationFramePool;
