import 'select2/dist/js/select2.full';
import './patchArrayAdapter';
import './defaultMultipleAdapter';
import './customAjaxAdapter';
import './customSearchAdapter';
import './createCustomDropdownAdapter';
import './customResultsAdapter';
import './addInfiniteScrollToResultsAdapter';

const select2Selector = 'select:not(.js-no-select2)';

app.listenToElement(select2Selector, bindSelect2, unbindSelect2, { delay: 50 });
app.bindSelect2 = bindSelect2;
app.unbindSelect2 = unbindSelect2;

app.listenEvents('reset   form', $form => {
  $form.find(select2Selector).val('').trigger('change');
});

app.listenEvents('select2:open', $document => {
  $document.find('.select2-search__field')[0].focus();
});

// eslint-disable-next-line import/prefer-default-export
export function bindSelect2($element, options = {}) {
  $element.select2({ ...optionsForSelect2ByElement($element[0]), ...options });
  const id = $element.attr('id');

  if (id) {
    $(`label[for="${id}"]`).on('click', handleClickLabelForSelect2);
  }
  // FUTURE: this code helps for touch performance optimisation
  // this and other changes in this commit resolve Select2 and Fastclick.js conflict
  // by https://github.com/select2/select2/issues/3222
  // const select2 = $element.data('select2');
  // select2.$container.find('*').addClass('needsclick');
}

function unbindSelect2($element) {
  if ($element.data('select2')) {
    $element.select2('destroy');
  }
}

function optionsForSelect2ByElement(element) {
  const multiple = element.hasAttribute('multiple');
  const searchInputOption = element.hasAttribute('data-search-input-option');
  const options = {
    // language: 'ru',
    closeOnSelect: !multiple,
    minimumResultsForSearch: element.hasAttribute('data-tags') ? 0 : 10,
    escapeMarkup: markup => markup,
    templateResult,
    templateSelection: templateResult,
    tags: searchInputOption,
  };

  readOptionsFromDataset(element, options);

  if (multiple) {
    options.selectionAdapter = $.fn.select2.amd.require(`select2/selection/${options.theme || 'default'}/multiple`);
  }
  options.dropdownAdapter = $.fn.select2.amd.require('select2/createCustomDropdownAdapter')(options);
  options.resultsAdapter = $.fn.select2.amd.require('select2/customResultsAdapter');

  if (element.hasAttribute('data-ajax--url')) {
    options.resultsAdapter = $.fn.select2.amd.require('select2/addInfiniteScrollToResultsAdapter')(
      options.resultsAdapter,
    );
    options.ajax = ajaxOptions(element);
    options.dataAdapter = $.fn.select2.amd.require('select2/data/customAjaxAdapter');
  }

  if (app.el.body.classList.contains('modal-open')) {
    const modals = app.modal.getModal();

    for (let i = modals.length - 1; i >= 0; i--) {
      const modalElement = modals[i];

      if (modalElement instanceof Element && modalElement.contains(element)) {
        options.dropdownParent = modalElement;
      }
    }
  }

  return options;
}

function readOptionsFromDataset(element, options) {
  ['theme', 'placeholder', 'allowClear', 'minimumInputLength', 'data', 'tags', 'tokenSeparators']
    .forEach(optionName => {
      readOptionFromData(optionName, element, options);
    });
  readOptionFromData('width', element, options, '100%');
}

function ajaxOptions(element) {
  return {
    dataType: 'json',
    cache: true,
    url: app.dom.data(element, 'ajax-Url'),
    delay: 250,
    data(parameters) {
      const newParameters = {
        q: parameters.term,
        page: parameters.page,
      };

      return {
        ...newParameters,
        ...app.dom.data(this[0], 'ajax-Params'),
      };
    },
  };
}

function readOptionFromData(key, element, options, defaultValue) {
  if (key in element.dataset) {
    options[key] = app.dom.data(element, key);
  } else if (defaultValue !== undefined) {
    options[key] = defaultValue;
  }
}

function handleClickLabelForSelect2(event) {
  const $label = $(event.currentTarget);

  $(`#${$label.attr('for')}`).select2('open');
}

function templateResult(result, container) {
  if (result.id) container.className += ' needsclick';
  const text = result.name || result.highlight || result.text;

  return result.image_url
    ? `<span style="background-image: url('${result.image_url}');" title="${text}" class="erp-thumb"></span> ${text}`
    : (
      result.type
        ? `<span class="badge text-bg-${typeColor(result.type)}">${result.type}</span> ${text}`
        : text
    );
}

function typeColor(type) {
  switch (type) {
    case 'Project':
      return 'primary';
    default:
      return 'light';
  }
}
