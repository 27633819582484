import 'flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { getLimit, getOppositeInput } from './date-range';

export const altFormats = {
  date: 'd.m.Y',
  'datetime-local': 'd.m.Y H:i',
  time: 'H:i',
  month: 'F Y',
};

export const formats = {
  date: 'Y-m-d',
  'datetime-local': 'Y-m-dTH:i',
  time: 'H:i',
  month: 'Y-m',
};

let flatpickrs = [];

// eslint-disable-next-line import/prefer-default-export
export function enterFlatpickrField($input) {
  const boundary = $input.data('rangeBoundary');

  if (boundary === 'to') {
    return;
  }
  const attributeType = $input.attr('type');
  const flatpickr = $input.flatpickr(getOptionsFromInput($input));

  flatpickr.originalAttrType = attributeType;
  flatpickrs.push(flatpickr);
}

app.listenToContent(undefined, $content => {
  const pickerInputs = $content.find('.flatpickr-input').get();

  flatpickrs = app.helpers.filter(flatpickrs, flatpickr => {
    if (app.helpers.includes(pickerInputs, flatpickr.element)) {
      flatpickr.destroy();
      const $flatpickr = $(flatpickr.element);

      $flatpickr.attr('type', flatpickr.originalAttrType);
      $flatpickr.val($flatpickr.attr('value'));

      return false;
    }

    return true;
  });
});

function getOptionsFromInput($input) {
  const attributeType = $input.attr('type');
  const options = {
    altInput: true,
    altFormat: altFormats[attributeType],
    dateFormat: formats[attributeType],
    allowInput: true,
    time_24hr: true,
    enableTime: attributeType === 'time' || attributeType === 'datetime-local',
    noCalendar: attributeType === 'time',
    locale: {
      firstDayOfWeek: 1, // start week on Monday
    },
    plugins: [],
    onReady(_selectedDates, _dateString, instance) {
      $(instance.calendarContainer).find('select').addClass('js-no-select2');

      if (attributeType === 'month') {
        // hide arrow year navigation due to unintuitive behavior
        // https://github.com/flatpickr/flatpickr/issues/2271
        $(instance.prevMonthNav).addClass('flatpickr-hide-month-nav');
        $(instance.nextMonthNav).addClass('flatpickr-hide-month-nav');
      }
    },
  };

  setMinMaxDates(options, $input);

  if ($input.attr('data-range')) {
    setOppositeInputHandler(options, $input);
  }

  if (attributeType === 'month') {
    // eslint-disable-next-line new-cap
    options.plugins.push(new monthSelectPlugin({
      shorthand: true,
      altFormat: altFormats[attributeType],
      dateFormat: formats[attributeType],
    }));
  }

  return options;
}

function setMinMaxDates(options, $input) {
  app.helpers.each(['min', 'max'], limitName => {
    const limitValue = getLimit($input[0], limitName);

    if (limitValue) {
      options[`${limitName}Date`] = limitValue;
    }
  });
}

function setOppositeInputHandler(options, $input) {
  const $oppositeInput = getOppositeInput($input);
  const oppositeInput = $oppositeInput[0];

  options.plugins.push(new rangePlugin({ input: oppositeInput })); // eslint-disable-line new-cap
  options.onChange = () => {
    $oppositeInput.trigger('change');
  };
}
