import browser from './browser';

// https://github.com/recurser/exif-orientation-examples
// https://www.daveperrett.com/articles/2012/07/28/exif-orientation-handling-is-a-ghetto/

function getExifData(file, imageSource, callback) {
  let width;
  let height;
  let orientation;
  const sizePromise = new Promise(resolve => {
    onLoadImage(imageSource, img => {
      width = img.width; // eslint-disable-line prefer-destructuring
      height = img.height; // eslint-disable-line prefer-destructuring
      resolve();
    });
  });
  const orientationPromise = new Promise(resolve => {
    getOrientationFromFile(file, orient => {
      orientation = orient;
      resolve();
    });
  });

  Promise.all([sizePromise, orientationPromise]).then(() => {
    callback({ width, height, orientation });
  });
}

function getExifTransformData(file, imageSource, callback) {
  getExifData(file, imageSource, ({ width, height, orientation }) => {
    callback(transformDataForImg(width, height, orientation));
  });
}

// function getOrientationFromBlobUrl(blobUrl, callback) {
//   const xhr = new XMLHttpRequest();
//   xhr.open('GET', blobUrl, true);
//   xhr.responseType = 'blob';
//   xhr.onload = function(_e) {
//     if (this.status === 200) {
//       getOrientationFromFile(this.response, callback);
//     }
//   };
//   xhr.send();
// }

function getOrientationFromFile(file, callback) {
  const reader = new FileReader();

  reader.addEventListener('load', event => {
    const view = new DataView(event.target.result);

    if (view.getUint16(0, false) !== 0xFFD8) {
      return callback(-2);
    }
    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        return callback(-1);
      }
      const marker = view.getUint16(offset, false);

      offset += 2;
      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) { // eslint-disable-line no-cond-assign
          return callback(-1);
        }

        const little = view.getUint16(offset += 6, false) === 0x4949;

        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);

        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) === 0x0112) {
            return callback(view.getUint16(offset + (i * 12) + 8, little));
          }
        }
      } else if ((marker & 0xFF00) !== 0xFF00) { // eslint-disable-line no-bitwise
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }

    return callback(-1);
  });
  reader.readAsArrayBuffer(file);
}

function transformDataForImg(width, height, orientation) {
  let rotate = [5, 6, 7, 8].includes(orientation);

  if (browser.os === 'iOS') {
    orientation = 0;
    if (rotate) {
      const w = width;

      width = height;
      height = w;
      rotate = false;
    }
  }
  const data = rotate ? { width: height, height: width } : { width, height };

  data.rotate = rotate;

  switch (orientation) {
    case 2:
      data.matrix = [-1, 0, 0, 1, width, 0];
      break;
    case 3:
      data.matrix = [-1, 0, 0, -1, width, height];
      break;
    case 4:
      data.matrix = [1, 0, 0, -1, 0, height];
      break;
    case 5:
      data.matrix = [0, 1, 1, 0, 0, 0];
      break;
    case 6:
      data.matrix = [0, 1, -1, 0, height, 0];
      break;
    case 7:
      data.matrix = [0, -1, -1, 0, height, width];
      break;
    case 8:
      data.matrix = [0, -1, 1, 0, 0, width];
      break;
    default:
    //   data.matrix = [1, 0, 0, 1, 0, 0];
  }

  return data;
}

function isMirroredByOrientation(orientation) {
  return orientation === 2 || orientation === 4 || orientation === 5 || orientation === 7;
}

function rotateDegByOrientation(orientation) {
  if (orientation === 3 || orientation === 4) {
    return 180;
  }
  if (orientation === 5 || orientation === 6) {
    return 90;
  }
  if (orientation === 7 || orientation === 8) {
    return 270;
  }

  return 0;
}

function transformByOrientation(orientation) {
  const transform = [];

  if (isMirroredByOrientation(orientation)) {
    transform.push('scaleX(-1)');
  }
  const angle = rotateDegByOrientation(orientation);

  if (angle !== 0) {
    transform.push(`rotate(${angle}deg)`);
  }

  return transform.join(' ');
}

// function transformFromData(transformData, windowSize) {
//   if (!transformData.width) {
//     return '';
//   }
//
//   const transform = [];
//
//   const translate = [
//     `${(windowSize.width - (transformData.rotate ? transformData.height : transformData.width)) / 2}px`,
//     `${(windowSize.height - (transformData.rotate ? transformData.width : transformData.height)) / 2}px`,
//   ];
//   transform.push(`translate(${translate.join(', ')})`);
//
//   if (transformData.matrix) {
//     const matrix = transformData.matrix.slice(0, -2).concat([0, 0]);
//     transform.push(`matrix(${matrix.join(', ')})`);
//   }
//
//   const windowRatio = windowSize.width / windowSize.height;
//   const imageRatio = transformData.width / transformData.height;
//   const scale = windowRatio > imageRatio
//     ? windowSize.height / transformData.height
//     : windowSize.width / transformData.width;
//   transform.push(`scale(${scale})`);
//
//   return transform.join(' ');
// }

function onLoadImage(imageSource, callback) {
  const img = new Image();

  img.addEventListener('load', () => {
    callback(img);
  });
  img.src = imageSource;
}

function isValidImageFileName(fileName) {
  return /\.(jpe?g|png|svg)$/i.test(fileName);
}

function readPreviewSource(file, callback) {
  const reader = new FileReader();

  reader.addEventListener('load', function() {
    callback(this.result);
  });
  reader.readAsDataURL(file);
}

app.images = {
  getExifData,
  getExifTransformData,
  getOrientationFromFile,
  transformByOrientation,
  onLoadImage,
  readPreviewSrc: readPreviewSource,
  isValidImageFileName,
};
