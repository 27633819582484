// requirements:
import isArray from 'lodash/isArray';
import capitalize from 'lodash/capitalize';

// options:
// const formFieldsError = 'Form filled with errors';
const defaultThrownError = 'Something is wrong!';
const detectPlainTextMaxLength = 100; // if response has plain text and its length fits, show it (-1 for disable)
const showBaseErrorAnyway = true;
const networkError = 'Connection to the server has been lost.';
const payloadTooLargeError = 'Your file is too large.';

// binding hook ajaxSuccess and ajaxError handlers
app.listenEvents({
  ajaxComplete(documentEl, event,
    { getResponseHeader, status, statusText, responseJSON, responseText, breakFlash, breakCookFlash }) {
    if (breakFlash) {
      return;
    }
    // primary convention about flash transport
    const flashHeader = getResponseHeader('X-Flash');
    const flashResponse = getFlashResponse(flashHeader, responseJSON);

    if (flashResponse) {
      handleFlashResponse(flashResponse, responseJSON);

      return;
    }

    if (isStatusWrong(status, statusText)) {
      return;
    }
    if (breakCookFlash) {
      return;
    }
    app.flash.alert(cookExceptionText(status, statusText, responseJSON, responseText));
  },
});

// private

function cookExceptionText(status, statusText, responseJSON, responseText) {
  const jsonErrors = responseJSON && responseJSON['errors'];

  if (jsonErrors) {
    try {
      return htmlUL(Object.keys(jsonErrors).map(fieldName => (
        `${capitalize(fieldName)}: ${jsonErrors[fieldName].join(', ')}`
      )));
    } catch (error) {
      return jsonErrors[0]?.detail || 'Something went wrong....';
    }
  }
  if (status === 0) {
    return networkError;
  }

  return `${messageFromResponseText(responseText) || statusText || defaultThrownError} [${status}]`;
}

function messageFromResponseText(responseText) {
  if (!responseText) {
    return;
  }
  // try detect Rails raise message
  const raiseMatches = responseText.match(/<\/h1>\n<pre>(.+?)<\/pre>/);

  if (raiseMatches) {
    return raiseMatches[1]; // eslint-disable-line consistent-return
  }
  // try detect short text message as error
  if (responseText.length <= detectPlainTextMaxLength) {
    return responseText; // eslint-disable-line consistent-return
  }
}

function htmlUL(list) {
  return `<ul>\n<li>${list.join('</li>\n<li>')}</li>\n</ul>`;
}

function getFlashResponse(flashHeader, responseJSON) {
  if (flashHeader) {
    return JSON.parse(decodeURIComponent(flashHeader.replace(/\+/g, '%20')));
  }

  return responseJSON && responseJSON['flash']; // deprecated transport method via JSON
}

function handleFlashResponse(flashResponse, responseJSON) {
  app.flash.auto(flashResponse);

  if (!showBaseErrorAnyway) {
    return;
  }

  if (responseJSON) {
    handleErrors(responseJSON);
  }
}

function handleErrors(responseJSON) {
  const errors = responseJSON['errors'] && responseJSON['errors']['base'] || responseJSON['error'];

  if (errors) {
    app.flash.alert(isArray(errors) ? htmlUL(errors) : errors);
  }
}

function isStatusWrong(status, statusText) {
  if (status === 413) {
    app.flash.alert(payloadTooLargeError);

    return true;
  }

  // about 404: https://github.com/DavyJonesLocker/client_side_validations/issues/297
  // eslint-disable-next-line yoda
  return (0 < status && status < 400) || status === 404 || statusText === 'canceled' || statusText === 'abort';
}
