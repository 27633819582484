export default class SuperFastSearch extends app.View {
  static selector = '.js-super-fast-search';
  static reflectOptions = ['urlPatterns'];
  static events = {
    'select2:select   select': 'handleChangeSelect',
  };

  handleChangeSelect($select) {
    const id = $select.val();
    const [selected] = $select.select2('data');

    app.visit(this.urlPatterns[selected.type].replace(':id', id));
  }
}
