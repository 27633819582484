// icons from sources of sweetalert 2.1.0
import '../../css/icons';
export var ICON = 'swal-icon';
export var iconMarkup = "<div class=\"".concat(ICON, "\"></div>");
export var errorIconMarkup = function () {
    var icon = "".concat(ICON, "--error");
    var line = "".concat(icon, "__line");
    var markup = "\n    <div class=\"".concat(icon, "__x-mark\">\n      <span class=\"").concat(line, " ").concat(line, "--left\"></span>\n      <span class=\"").concat(line, " ").concat(line, "--right\"></span>\n    </div>\n  ");
    return markup;
};
// export const warningIconMarkup = (): string => {
//   const icon = `${ICON}--warning`;
//
//   return `
//     <span class="${icon}__body">
//       <span class="${icon}__dot"></span>
//     </span>
//   `;
// };
export var successIconMarkup = function () {
    var icon = "".concat(ICON, "--success");
    return "\n    <span class=\"".concat(icon, "__line ").concat(icon, "__line--long\"></span>\n    <span class=\"").concat(icon, "__line ").concat(icon, "__line--tip\"></span>\n\n    <div class=\"").concat(icon, "__ring\"></div>\n    <div class=\"").concat(icon, "__hide-corners\"></div>\n  ");
};
