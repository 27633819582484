import 'jquery-ujs';
import initIcon from '../vendor/sweetalert/modules/init/icon';
import { tryReplaceTr, tryAddTr, tryReplaceEl } from './ujsTableHelpers';

// bootstrap `disabled` class when loading ujs-element with disabled-with.
const originalDisableElement = $.rails.disableElement;

$.rails.disableElement = $element => {
  originalDisableElement($element);
  $element.addClass('disabled');
};

const originalEnableElement = $.rails.enableElement;

$.rails.enableElement = $element => {
  $element.removeClass('disabled');
  originalEnableElement($element);
};

$(document).off('ajax:complete.rails', $.rails.formSubmitSelector);

app.listenToElement('form:not([target^="iframe-"])', $form => {
  app.forms.focusFirstErrorField($form);
}, null, { delay: 100 });

app.listenEvents({
  'form': { 'reset': app.forms.clearErrors }, // eslint-disable-line quote-props

  // '[data-remote]': {
  //   'ajax:beforeSend': $remote => {
  //     app.dom.data($remote[0], 'remoteLoading', 'true');
  //   },
  //
  //   'ajax:complete': ($remote, event, xhr) => {
  //     app.dom.removeData($remote[0], 'remoteLoading');
  //     // const location = xhr.getResponseHeader('Location');
  //     // if (location && !$remote.is('.no-relocation')) {
  //     //   app.visit(location);
  //     // }
  //   },
  // },

  // 'a[data-remote]': {
  //   'ajax:beforeSend': ($link, event) => {
  //     toggleLink(event, true);
  //   },
  //
  //   'ajax:complete': ($link, event) => {
  //     toggleLink(event, false);
  //   },
  // },

  'form[data-remote]': {
    // 'ajax:beforeSend': $form => {
    //   // toggleLoadingForm($form, true);
    // },

    'ajax:success': ($form, event, data, status, xhr) => {
      if (event.target !== $form[0]) {
        return;
      }
      app.forms.clearErrors($form);
      $form.data('lastSuccess', true);
      showIconInModal($form, 'success', $modal => {
        redirectOnSuccess($form, xhr);
        if ($modal) {
          tryUpdateTable($modal, $form, data);
        }
      });
    },

    'ajax:error': ($form, event, xhr) => {
      // toggleLoadingForm($form, false);
      if (event.target !== $form[0]) {
        return;
      }
      $.rails.enableFormElements($form);
      app.forms.clearErrors($form);
      if (xhr.responseJSON) {
        handleResponse(xhr, $form);
      }
      showIconInModal($form, 'error');
    },
  },
});

// function toggleLink(event, disabled) {
//   $(event.currentTarget).toggleClass('disabled', disabled);
// }

// function toggleLoadingForm($form, loading) {
//   $form.find(':submit').prop('disabled', loading);
// }

export default function redirectOnSuccess($form, xhr) {
  const location = xhr.getResponseHeader('Location') || xhr.responseJSON && xhr.responseJSON.location;

  if (location && $form.data('redirectOnSuccess') !== false) {
    $form.find(':submit').data('preventDisabled', true);
    app.visit(location);

    return true;
  }
  $.rails.enableFormElements($form);

  return false;
}

function showIconInModal($form, iconName, callback = null) {
  let $modalContent = $form.find('.modal-content');

  if ($modalContent.length === 0) {
    $modalContent = $form.closest('.modal-content');
  }
  if ($modalContent.length === 0) {
    if (callback) {
      callback();
    }

    return;
  }
  let $icon;

  initIcon(iconName, iconMarkup => {
    $icon = $(iconMarkup);
    $icon.appendTo($modalContent);
    $form.data('formResultIcon', $icon[0]);

    return $icon[0];
  }, () => {
    if (callback) {
      callback(iconName === 'success' ? $modalContent.closest('.modal').modal('hide') : undefined);
    } else {
      $icon.remove();
    }
  });
}

function tryUpdateTable($modal, $form, data) {
  // It means we got returned whole element and what to replace it.
  if (data && data['el'] && data['html']) {
    tryReplaceEl(data);

    return;
  }

  if (data && (data['tr'] || data['td'])) {
    if ($form.attr('id').startsWith('new_')) {
      tryAddTr($modal.data('$trigger'), data);
    } else {
      tryReplaceTr($modal.data('$trigger'), data);
    }
  } else {
    $modal.data('$trigger')?.trigger('update');
  }
}

function handleResponse(xhr, $form) {
  if (xhr.responseJSON.errors) {
    if (app.forms.fillErrors($form, xhr.responseJSON.errors)) {
      xhr.breakCookFlash = true;
    }
  } else if (xhr.responseJSON.error) {
    app.forms.allInvalid($form);
    app.forms.focusFirstErrorField($form);
  }
}
