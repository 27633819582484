const { filterUrl, getUrlParams } = app.shared.lib.url;

export default class FormParametersFilter extends app.View {
    static selector = 'form[method="get"]';

    static events = {
      'ajax:beforeSend': 'onSubmit',
      'ajax:complete': 'enableInputs',
    };

    enableInputs(form) {
      $(form).find('input:disabled, select:disabled, textarea:disabled').each(function() {
        $(this).prop('disabled', false);
      });
    }

    onSubmit(el) {
      const formElement = $(el[0]);
      const actionURL = filterUrl(formElement);
      const searchParameters = getUrlParams(actionURL);

      $.each($('input,select,textarea', $(formElement)), function() {
        const name = $(this).attr('name');

        if (searchParameters.findIndex(item => item.name === name) === -1) {
          $(this).prop('disabled', true);
        }
      });
    }
}
