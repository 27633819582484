export default class Slot extends app.View {
  static selector = '.js-slot';
  static events = {
    'change   .js-user-select': 'handleChangeUser',
  };

  initialize() {
    this.$userSelect = this.$('.js-user-select');
    this.$categorySelect = this.$('.js-category-select');
    const userData = this.$userSelect.data('initialState');
    const categoryId = this.$categorySelect.val();

    if (userData && !categoryId && userData.category_id) {
      setTimeout(() => { this.setCategoryId(userData.category_id); }, 1000);
    }
  }

  handleChangeUser() {
    const userData = this.getUserData();

    if (userData) {
      this.updateUserInfoLink(userData);
      if (userData.category_id) {
        this.setCategoryId(userData.category_id);
      }
    }
  }

  getUserData() {
    const datas = this.$userSelect.select2('data');

    return datas && datas[0];
  }

  setCategoryId(categoryId) {
    this.$categorySelect
      .val(categoryId)
      .trigger('change');
  }

  updateUserInfoLink(userData) {
    if (userData) {
      const $userInfoLink = this.$('.user-chip > .btn-link');

      if ($userInfoLink.length > 0) {
        $userInfoLink.attr('href', $userInfoLink.attr('href').replace(/\d+|:user_id/, userData.id));
        $userInfoLink.attr('data-title', userData.text);
        $userInfoLink.data('title', userData.text);
      }
    }
  }
}
