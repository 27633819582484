import camelCase from 'lodash/camelCase';

const dateRangeSelector = 'input[type="date"][data-range]';

export function enterDateRangeField($input) {
  const input = $input[0];

  app.helpers.each(['min', 'max'], limitName => {
    const limitValue = getLimit(input, limitName);

    if (limitValue) {
      setInitialLimit(input, limitName, limitValue);
    }
  });
}

app.listenEvents('reset   form', $form => {
  const $inputs = $form.find(dateRangeSelector).filter$($input => $input.prop('type') === 'date');

  if ($inputs.length > 0) {
    $inputs.each((index, input) => {
      app.helpers.each(['min', 'max'], limitName => {
        const limitValue = getInitialLimit(input, limitName);

        setLimit(input, limitName, limitValue);
      });
    });
  }
});

app.listenEvents(dateRangeSelector, 'change', $input => {
  if ($input.prop('type') !== 'date' || app.dom.memoryData($input[0], 'changing')) {
    setLimit($input[0], 'min', $input.val() || getInitialLimit($input[0], 'min'));
  }
  const $oppositeInput = getOppositeInput($input);
  const boundary = $input.data('rangeBoundary');
  const oppositeLimit = boundary === 'from' ? 'min' : 'max';

  setLimit($oppositeInput[0], oppositeLimit, $input.val() || getInitialLimit($oppositeInput[0], oppositeLimit));
});

function setLimit(input, limitKey, value) {
  app.dom.memoryData(input, 'changing', true);
  if (value) {
    input[limitKey] = value;
  } else {
    input.removeAttribute(limitKey);
  }
  app.dom.memoryData(input, 'changing', false);
}

export function getLimit(input, limitKey) {
  return input[limitKey];
}

function setInitialLimit(input, limitKey, value) {
  app.dom.memoryData(input, initialLimitKey(limitKey), value);
}

function getInitialLimit(input, limitKey) {
  return app.dom.memoryData(input, initialLimitKey(limitKey));
}

function initialLimitKey(limitKey) {
  return camelCase(`initial_${limitKey}`);
}

export function getOppositeInput($input) {
  let $opposite = $input.data('oppositeInput');

  if (!$opposite) {
    const range = $input.data('range');

    $opposite = $input.nearestFind(`${dateRangeSelector.slice(0, -1)}="${range}"]`);
    $input.data('oppositeInput', $opposite);
    $opposite.data('oppositeInput', $input);
  }

  return $opposite;
}
