$.fn.select2.amd.require([
  'select2/utils',
  'select2/data/array',
], (Utils, ArrayAdapter) => {
  const { convertToOptions } = ArrayAdapter.prototype;

  ArrayAdapter.prototype.convertToOptions = function(data) {
    return convertToOptions.call(this, typeof data === 'function' ? data() : data);
  };
});
