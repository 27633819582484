const slideDuration = 16 * 16;

window.addEventListener('online', () => {
  getAlert$().slideUp(slideDuration);
});

window.addEventListener('offline', () => {
  getAlert$().slideDown(slideDuration);
});

app.listenToPage(() => {
  getAlert$().slideToggleByState(!navigator.onLine, slideDuration);
});

app.listenEvents('click   .js-offline-alert-close', $close => {
  $close.closest('.alert').slideUp(slideDuration);
});

const offlineText = 'Your device has lost internet connection.';
const closeButton = `<button
  type="button"
  class="btn-close js-offline-alert-close"
  data-bs-dismiss="alert"
  aria-label="Close">
  </button>`;
const alertClasses = 'js-offline-alert alert alert-danger alert-dismissible';
const alertTemplate = `<div class="${alertClasses} text-center sticky-top t-2">${offlineText} ${closeButton}</div>`;

function getAlert$() {
  let $offlineAlert = $('.js-offline-alert');

  if ($offlineAlert.length === 0) {
    $offlineAlert = $(alertTemplate);
    $offlineAlert.hide().prependTo('body > .container, body > .container-fluid');
  }

  return $offlineAlert;
}
