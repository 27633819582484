app.listenToElement('.form-group:not(.js-react) > label:first-child', $label => {
  let $input = $label.next(':input');
  // detect multi-select after hidden input with default value
  const $select = $input.next(`select[name="${$input.attr('name')}"]`);

  if ($select.length > 0) {
    $input = $select;
  }
  if ($input.length > 0) {
    const labelFloat = toggleFloat($input);

    $label.toggleClass('erp-label-float', labelFloat);
    $input.toggleClass('erp-input-float', !labelFloat);
    $input.toggleClass('blank', !labelFloat);
  }
});

app.listenEvents(
  'change/focusin/focusout/select2:open/select2:close   .form-group:not(.js-react) > label:first-child ~ :input',
  ($input, event) => {
    const $label = $input.prevAll('label').first();
    const labelFloat = event.type === 'focusin' || event.type === 'select2:open' || toggleFloat($input);

    if (labelFloat !== $label.hasClass('erp-label-float')) {
      $label.toggleClass('erp-label-float', labelFloat);
      $input.toggleClass('erp-input-float', !labelFloat);
      $input.toggleClass('blank', labelFloat);
    }
  },
);

function toggleFloat($input) {
  return isFocused($input)
    || !!$input.attr('placeholder')
    || !!$input.attr('data-placeholder')
    || !inputIsValid($input)
    || !inputIsEmpty($input);
}

function inputIsValid($input) {
  return $input[0].validity === undefined || $input[0].validity.valid;
}

function inputIsEmpty($input) {
  const value = $input.val();

  return value === null || value.length === 0;
}

function isFocused($input) {
  return $input[0] === document.activeElement;
}
