import subscribeAnimationFrame from '../lib/subscribeAnimationFrame';

const progressBarAnimationDuration = 600;

function makeProgressEvents(getProgressBar) {
  return {
    'ajax:send': () => {
      getProgressBar().addClass('progress-bar-animated');
    },
    'ajax:error': () => {
      getProgressBar().removeClass('progress-bar-animated');
    },
  };
}

function getProgressBarValue($progressBar, valueType = 'now') {
  return parseFloat($progressBar.attr(`aria-value${valueType}`));
}

function changeProgressBarValue($progressBar, newValue, finishCallback, progressCallback) {
  const min = getProgressBarValue($progressBar, 'min');
  const max = getProgressBarValue($progressBar, 'max');

  newValue = Math.max(min, Math.min(newValue, max));
  $progressBar[0].style.width = `${(newValue - min) / (max - min) * 100}%`;
  $progressBar.nearestFind('.js-progress-value').html(newValue);
  $progressBar.attr('aria-valuenow', newValue);
  if (progressCallback) {
    subscribeProgressBarAnimation($progressBar, newValue, finishCallback, progressCallback);
  } else {
    setTimeout(() => {
      finishProgress($progressBar, finishCallback);
    }, progressBarAnimationDuration);
  }
}

function subscribeProgressBarAnimation($progressBar, newValue, finishCallback, progressCallback) {
  const prevValue = getProgressBarValue($progressBar);
  let finishTime = null;

  subscribeAnimationFrame(null,
    ({ timestamp }) => {
      progressCallback(newValue - (newValue - prevValue) * (finishTime - timestamp) / progressBarAnimationDuration);
    },
    (newProps, timestamp) => {
      if (!finishTime) {
        finishTime = timestamp + progressBarAnimationDuration;
      }
      const doAgain = timestamp < finishTime;

      if (!doAgain) {
        progressCallback(newValue);
        finishProgress($progressBar, finishCallback);
      }

      return doAgain;
    },
    (newProps, timestamp) => ({ ...newProps, timestamp }));
}

function finishProgress($progressBar, finishCallback) {
  $progressBar.removeClass('progress-bar-animated');
  if (finishCallback) {
    finishCallback();
  }
}

function template(value, valueMin = 0, valueMax = 100) {
  value = Math.min(Math.max(valueMin, value), valueMax);
  const percent = 100 * (value - valueMin) / (valueMax - valueMin);

  return `<div class="progress">
    <div 
      class="progress-bar progress-bar-striped progress-bar-animated" 
      role="progressbar" 
      aria-valuenow="${value}" 
      aria-valuemin="${valueMin}" 
      aria-valuemax="${valueMax}" 
      style="width: ${percent}%"
    ></div>
  </div>`;
}

app.progressBar = {
  makeProgressEvents,
  getProgressBarValue,
  changeProgressBarValue,
  template,
};
