var _a, _b;
import { modalUrlParameter, modalName } from './query-parameters';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getUrlParameters as getUrlParametersNoType } from '../lib/url';
var getUrlParameters = getUrlParametersNoType;
var getParameterByName = function (name, url) {
    var parameters = getUrlParameters(url) || [];
    var findItem = parameters.find(function (item) { return item.name === name; });
    return findItem === null || findItem === void 0 ? void 0 : findItem.value;
};
var triggerNameByModalParameter = (_a = {},
    _a[modalUrlParameter.vacation] = 'vacationModal',
    _a[modalUrlParameter.sickVacations] = 'vacationModal',
    _a[modalUrlParameter.expense] = 'expense',
    _a[modalUrlParameter.taxes] = 'expense',
    _a[modalUrlParameter.bonus] = 'expense',
    _a.historyModal = 'historyModal',
    _a.actionsHistoryModal = 'actionsHistoryModal',
    _a);
var expensesCategoriesByModalParameter = (_b = {},
    _b[modalUrlParameter.taxes] = 'Taxes',
    _b[modalUrlParameter.bonus] = 'HR',
    _b);
var handleURLChange = function () {
    var modal = getParameterByName(modalName, window.location.href);
    if (modal) {
        var triggerName = triggerNameByModalParameter[modal];
        var modalButton = document.querySelector("[data-trigger=\"".concat(triggerName, "\"]"));
        if (modalButton) {
            var expensesWithChangedCategory = modal === modalUrlParameter.taxes
                || modal === modalUrlParameter.bonus;
            if (expensesWithChangedCategory) {
                modalButton.setAttribute('href', "/expenses/new?category=".concat(expensesCategoriesByModalParameter[modal]));
                modalButton.click();
                modalButton.setAttribute('href', '/expenses/new');
            }
            else {
                modalButton.click();
            }
        }
    }
};
app.listenEvents('turbolinks:load', handleURLChange);
